<template>
  <AuthWrapper>
    <div class="auth-contents">
      <div class="auth-container">
        <sdHeading as="h2" class="sign-in__header"
          >{{ i18n.t('authPage.passwordReset') }}
          <a-select
            class="sign-in__language"
            v-model:value="currentLanguage"
            @change="changeLanguage"
            :options="languages"
            ref="select"
          >
          </a-select>
        </sdHeading>
        <a-form @finish="handleSubmit" :rules="rules" :model="formState" layout="vertical">
          <a-form-item name="password" :label="i18n.t('editPasswordView.newPassword')">
            <a-input-password type="password" v-model:value="formState.password" />
          </a-form-item>
          <a-form-item name="password_confirmation" :label="i18n.t('editPasswordView.confirmPassword')">
            <a-input-password type="password" v-model:value="formState.password_confirmation" />
          </a-form-item>
          <a-form-item>
            <div class="auth-form-action">
              <sdButton class="btn-signin" htmlType="submit" :disabled="isLoading" type="primary" size="large">
                {{ i18n.t('authPage.sendResetPasswordInstructions') }}
              </sdButton>
              <p class="sign-up__to-login">
                <router-link to="/auth/login"> {{ i18n.t('authPage.signIn') }}</router-link>
              </p>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </AuthWrapper>
</template>
<script>
import { computed, reactive, ref } from 'vue';
import { AuthWrapper } from './style';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { languages } from '@/config/helpers/JSONdata.js';
import { getCookie, setCookie } from '@/utility/localStorageControl';

const ForgotPassword = {
  name: 'ForgotPassword',
  components: { AuthWrapper },
  setup() {
    const route = useRoute();
    const { state, dispatch } = useStore();
    const i18n = useI18n();
    const reset_password_token = route.params.confirmationCode;
    const router = useRouter();

    const isLoading = computed(() => state.auth.loading);
    const messageReset = computed(() => state.auth.message);
    const currentLanguage = ref(getCookie('language'));

    const formState = ref({
      password_confirmation: '',
      password: '',
    });

    const passwordValidator = ({ field }) => {
      if (!rules[field][0].trigger[1]) {
        rules[field][0].trigger[1] = 'change';
      }
      if (formState.value[field] == '') {
        return Promise.reject(i18n.t('authPage.errorPassword'));
      } else if (formState.value[field].length < 6) {
        return Promise.reject(i18n.t('authPage.errorPasswordMin'));
      }
      return Promise.resolve();
    };

    const rules = reactive({
      password: [
        {
          trigger: ['blur'],
          validator: passwordValidator,
        },
      ],
      password_confirmation: [
        {
          trigger: ['blur'],
          validator: passwordValidator,
        },
      ],
    });

    const handleSubmit = () => {
      dispatch('auth/resetPassword', {
        reset_password_token,
        password_confirmation: formState.value.password_confirmation,
        password: formState.value.password,
      }).then(() => {
        if (messageReset.value.errors) {
          Object.entries(messageReset.value.errors).map((value) => {
            message.error(value[0] == 'reset_password_token' ? i18n.t('authPage.token', 2) + value[1][0] : value[1][0]);
          });
        } else {
          message.success(messageReset.value.success);
          router.push('auth/login');
        }
      });
    };

    const changeLanguage = (data) => {
      currentLanguage.value = data;
      i18n.locale.value = data;
      setCookie({ name: 'language', value: data });
    };

    return {
      messageReset,
      i18n,
      formState,
      rules,
      isLoading,
      languages,
      currentLanguage,
      handleSubmit,
      changeLanguage,
    };
  },
};

export default ForgotPassword;
</script>

<style scoped lang="scss">
.sign-up__to-login {
  font-size: 20px;
  font-weight: 600;
  @media screen and (max-width: 400px) {
    margin-top: 20px;
  }
}
</style>
