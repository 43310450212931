<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form @finish="handleSubmit" :model="formState" layout="vertical" class="auth-container">
        <sdHeading as="h3" class="sign-in__header">
          {{
            operationName == 'resendCode'
              ? i18n.t('authPage.resendConfirmCodeAgain')
              : i18n.t('authPage.forgotPassword')
          }}
          <a-select
            class="sign-in__language"
            v-model:value="currentLanguage"
            @change="changeLanguage"
            :options="languages"
            ref="select"
          >
          </a-select>
        </sdHeading>
        <p class="forgot-text">
          {{ operationName == 'resendCode' ? i18n.t('authPage.resetCodeInfo') : i18n.t('authPage.resetPassInfo') }}
        </p>
        <a-form-item :label="i18n.t('authPage.email')" name="email">
          <a-input type="email" v-model:value="formState.email" required placeholder="name@example.com" />
        </a-form-item>
        <a-form-item>
          <sdButton class="btn-reset" htmlType="submit" :disabled="isLoading" type="primary" size="large">
            {{
              operationName == 'resendCode'
                ? i18n.t('authPage.sendResetCodeInstructions')
                : i18n.t('authPage.sendResetPasswordInstructions')
            }}
          </sdButton>
        </a-form-item>
        <div class="auth-form-action">
          <p class="sign-in-up">
            <router-link :to="{ name: 'login' }">
              {{ i18n.t('authPage.signIn') }}
            </router-link>
          </p>
          <p class="sign-in-up">
            <router-link :to="{ name: 'register' }">
              {{ i18n.t('authPage.signUp') }}
            </router-link>
          </p>
        </div>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { reactive, computed, ref } from 'vue';
import { AuthWrapper } from './style';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { languages } from '@/config/helpers/JSONdata.js';
import { getCookie, setCookie } from '@/utility/localStorageControl';

const ForgotPassword = {
  name: 'ForgotPassword',
  components: { AuthWrapper },
  setup() {
    const { dispatch, state } = useStore();
    const route = useRoute();
    const i18n = useI18n();

    const formState = reactive({
      email: '',
    });
    const currentLanguage = ref(getCookie('language'));

    const operationName = computed(() => route.name);
    const messageForgot = computed(() => state.auth.message);
    const messageReconfirm = computed(() => state.profile.mailAcc);
    const isLoading = computed(() => state.auth.loading);

    const handleSubmit = () => {
      formState.locale = getCookie('language');
      if (operationName.value == 'resendCode') {
        dispatch('mailReconfirmation', formState).then(() => {
          if (messageReconfirm.value.errors) {
            message.error(i18n.t('messages.thisEmail') + messageReconfirm.value.errors.email[0]);
          } else {
            message.success(i18n.t('messages.' + messageReconfirm.value.success));
          }
        });
      } else {
        dispatch('auth/forgotPassword', formState).then(() => {
          if (messageForgot.value.errors) {
            message.error(i18n.t('messages.thisEmail') + messageForgot.value.errors.email[0]);
          } else {
            message.success(i18n.t('messages.' + messageForgot.value.success));
          }
        });
      }
    };

    const changeLanguage = (data) => {
      currentLanguage.value = data;
      i18n.locale.value = data;
      setCookie({ name: 'language', value: data });
    };
    return {
      handleSubmit,
      formState,
      i18n,
      operationName,
      isLoading,
      languages,
      currentLanguage,
      changeLanguage,
    };
  },
};

export default ForgotPassword;
</script>
<style scoped lang="scss">
.sign-in-up {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  @media screen and (max-width: 400px) {
    margin-top: 20px;
  }
}
</style>
